import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/d74WPKEW9S8"
    bibleGroupSrc="https://pdfhost.io/v/SM9ummCJJ_Microsoft_Word_Bible_Group_Homework_523docx.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Joy-revealing Light - The Pursuit of Happiness" />
  </Layout>
)

export default SermonPost
